<template>
  <section>
    <navi-bar>
      {{$t('front.common.depositApply')}}
    </navi-bar>

    <template v-if="type == 'apply'">
      <div class="acountapply">
        <a @click="moneyinfo=!moneyinfo"><img src="@/assets_mobile/img/icon_question.svg" /></a>
        <a class="acountbtn"  @click="getAcc">{{$t('front.cash.moneyCheckListG')}}</a>
        <!--a class="acountbtn" href="https://apple7.kr/shop/item.php?it_id=1686579397" target="_blank">{{$t('front.cash.coinCharge')}}</a-->
      </div>
      <div class="appliwrite">
        <input type="text" pattern="[0-9.,]+" class="numb" :placeholder="$t('front.give.moneyInput')" v-model="setCurrentChargeMoney"/>
        <img src="@/assets_mobile/img/icon_delete.svg" @click="setCurrentChargeMoney = ''"/></div>
      <ul class="applihave">
        <li><img src="@/assets_mobile/img/icon_exclamat.svg" /></li>
        <li>{{$t('front.cash.moneyHave')}} :</li>
        <li class="">{{thousand(userData.cashAmt)}}{{$t('front.common.money')}}</li>
      </ul>
      <ul class="applimoneybtn">
        <li><a @click="setMoney(10000)">+1{{$t('front.cash.manwon')}}</a></li>
        <li><a @click="setMoney(50000)">+5{{$t('front.cash.manwon')}}</a></li>
        <li><a @click="setMoney(100000)">+10{{$t('front.cash.manwon')}}</a></li>
        <li><a @click="setMoney(1000000)">+100{{$t('front.cash.manwon')}}</a></li>
      </ul>
      <div class="moneyread">
        <h3><!--img src="@/assets_mobile/img/mypage_point_waring.png" /-->{{$t('front.cash.moneyCheckList')}}</h3>
        <ul>
          <li><!--img src="@/assets_mobile/img/icon_check.png" /--><span>{{$t('front.cash.moneyCheckListA')}}</span></li>
          <li><!--img src="@/assets_mobile/img/icon_check.png" /--><span>{{$t('front.cash.moneyCheckListB')}}</span></li>
          <li><!--img src="@/assets_mobile/img/icon_check.png" /--><span>{{$t('front.cash.moneyCheckListC')}}</span></li>
          <li><!--img src="@/assets_mobile/img/icon_check.png" /--><span>{{$t('front.cash.moneyCheckListD')}}</span></li>
        </ul>
      </div>

      <div class="moneyinfowrap" v-if="moneyinfo">
        <div class="moneyinfoappli">
          <a class="close" @click="moneyinfo=!moneyinfo"><img src="@/assets_mobile/img/icon_delete.svg" /></a>
          <h3 class="applih">{{$t('front.cash.moneyCheckListE')}}</h3>
          <p class="applip">{{$t('front.cash.moneyCheckListF')}}</p>
          <!--div class="btnwrap">
            <a class="appli" @click="getAcc">계좌번호 신청</a>
          </div-->
          <ul class="applistep">
            <li><em>1</em><span>{{$t('front.cash.we')}} <strong>{{$t('front.cash.moneyCheckListG')}}</strong>{{$t('front.cash.moneyCheckListH')}}</span></li>
            <li><em>2</em><span>{{$t('front.cash.moneyCheckListI')}}</span></li>
            <li><em>3</em><span><strong>{{$t('front.cash.moneyInput')}}</strong>{{$t('front.cash.moneyCheckListK')}} <strong>{{$t('front.cash.moneyCheckListL')}}</strong>{{$t('front.cash.moneyCheckListM')}}</span></li>
          </ul>
        </div>

        <!--ul class="appliinput">
          <li>보너스 금액</li>
          <li class="bbl">{{thousand(bonus)}}</li>
        </ul-->
        <!--ul class="appliinput">
          <li>보너스 혜택</li>
          <li class="btnwrap m0">
            <a class="all"><input type="checkbox" :checked="isApplyBonus === true" @click="isApplyBonus = true"/>받기</a>
            <a class="selec"><input type="checkbox" :checked="isApplyBonus === false" @click="isApplyBonus = false"/>받지않기</a>
          </li>
        </ul-->
      </div>

      <div class="btnwrap">
        <a class="ok" @click="onSubmit">{{$t('front.board.apply')}}</a>
      </div>
      <div class="applylistbtn mb100">
        <a :class="{'on' : type == 'list'}" @click="type = 'list'">{{$t('front.cash.depositCheck')}} ></a>
      </div>
      <!--div class="moneytab">
        <a :class="{'on' : type == 'apply'}" @click="type = 'apply'"><span>입금신청</span></a>
        <a :class="{'on' : type == 'list'}" @click="type = 'list'"><span>입금내역</span></a>
      </div-->
    </template>

    <template v-if="type == 'list'">
      <date-filter-mobile @search="onCashListSearch"
                          @update="onChangeDate"
      />

      <div class="gamebetwrap">
        <template v-if="cashList.length > 0">
          <div class="gamebetlist" v-for="item in cashList" v-bind:key="item.cashIdx">
            <a class="close"><img src="@/assets_mobile/img/icon_delete.svg" /></a>
            <!--input type="checkbox" v-model="item.checked"-->
            <ul class="gamebetdetail textleft">
              <li class="ft13">{{computedCashStatus(item.cashStatus).text}} ({{dateFormat(item.regDate)}})</li>
              <li class="ft15 mt10">{{$t('front.board.processMoney')}}</li>
              <!--li><span>보너스금액</span>:<span class="rd">{{thousand(item.preCashAmt)}}</span></li-->
            </ul>
            <ul class="gamebetdetail textright">
              <li class="mt30 ft15"><span class="rd fb">{{thousand(item.cashAmt)}}</span> {{$t('front.common.money')}}</li>
              <!--li class="gr ft13 mt10">{{dateFormat(item.updDate)}}</li-->
            </ul>
            <!--a :class="computedCashStatus(item.cashStatus).class">{{computedCashStatus(item.cashStatus).text}}</a-->
          </div>
        </template>
        <template v-else>
          <div class="gamebetlist">
            {{$t('front.common.notFoundList')}}
          </div>
        </template>

      </div>
      <!--div class="btnwrap">
        <a class="all" @click="onAllCheck('cashList')">{{allChecked ? '전체해제': '전체선택'}}</a>
        <a class="selec" @click="onRemoveMsg">선택삭제</a>
      </div-->
      <pagination-mobile v-if="pageInfo"
                         :pageNum="pageInfo.page"
                         :pageSize="pageInfo.count_per_list"
                         :totalCount="pageInfo.tatal_list_count"
                         @goToPage="onCashListSearchMobile"
      />
      <div class="applylistbtn left mt10 mb100">
        <a :class="{'on' : type == 'apply'}" @click="type = 'apply'"> &lt; {{$t('front.common.depositApply')}}</a>
      </div>
    </template>
    <form name=CoinwikiForm action="https://www.coinwiki.shop/newmatching/" target="" method="post">
      <input type=hidden name="groupid" :value="'stca1'">
      <input type=hidden name="groupkey" :value="'z0NEQAzwd3xXYvd7'">
      <input type=hidden name="groupuserid" :value="userData.memId">
      <input type=hidden name="groupusername" :value="userData.memName">
      <input type=hidden name="groupstr1">
      <input type=hidden name="groupstr2">
      <input type=hidden name="returntrueurl">
      <input type=hidden name="returnfalseurl">
    </form>
  </section>
</template>

<script>

import NaviBar from '@/components/common/mobile/NaviBar'
import PaginationMobile from '@/components/ui/PaginationMobile'
import DateFilterMobile from '@/components/ui/DateFilterMobile'
import Charge from '@/views/member/money/charge'

export default {
  name: 'charge',
  data () {
    return {
      moneyinfo: false
    }
  },
  components: {
    NaviBar,
    PaginationMobile,
    DateFilterMobile
  },
  mixins: [
    Charge
  ],
  methods: {
    async getAcc () {
      const title = '계좌문의입니다.'
      const content = '계좌문의입니다.'
      const params = {
        title: title,
        content: content,
        boardType: 'faq',
        category: '',
        faqType: 'bankaccount'
      }

      await this.setSaveAccountBoard(params, async function () {
        await this.loadList()
      }.bind(this))
    }
  }
}
</script>
<style scoped src="@/styles_mobile/betlist.css"></style>
<style scoped src="@/styles_mobile/money.css"></style>
<style scoped src="@/styles_mobile/subcommon.css"></style>
<style scoped src="@/styles_mobile/common.css"></style>

<style>
.morebtn {margin-bottom: 10px !important;}
</style>
